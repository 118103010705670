<template>
    <div v-if="comp" class="frm">
        <div class="page-header">银行信息</div>
        <div class="">
            <CertState :comp="comp"></CertState>
        </div>
        <!-- v-if="comp.cert_stat==2" -->
        <div class="block">
            <div class="header">银行信息</div>
            <div class="info-list">
                <div>
                    <label>开户行名称</label>
                    <span v-if="comp.bank_subbranch">{{comp.bank_subbranch}}</span>
                    <span v-else>--</span>
                </div>
                <div>
                    <label>对公账号</label>
                    <span v-if="comp.bank_id_code">{{bank_id_code}}</span>
                    <span v-else>--</span>
                    <span class="isview" @click="switch_view()">
                        <i class="iconfont" :class="isView ? 'icon-yanjing1' : 'icon-yanjing_bi'"></i>
                    </span>
                </div>
                <div>
                    <label>开户银行</label>
                    <span v-if="comp.bank_name">{{comp.bank_name}}</span>
                    <span v-else>--</span>
                </div>
                <!-- <div>
                    <label>开户地点</label>
                    <span>{{ }}</span>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
    import CertState from './CertState'
    import { getCompInfoById } from '@/service/company';
    export default {
        components: {
            CertState
        },
        data() {
            return {
                comp: null,
                bank_id_code: null,
                isView: false,
            }
        },
        async created() {
            this.comp = await getCompInfoById(this.$store.state.loginUser.comp.id);
            if (this.comp.bank_id_code) {
                this.switch_view();
            }
        },
        methods: {
            switch_view() {
                if (this.isView) {
                    this.bank_id_code = this.comp.bank_id_code;
                    this.isView = false;
                } else {
                    this.isView = true;
                    this.bank_id_code = this.hideBankCard(this.comp.bank_id_code);
                }
            },
            hideBankCard(value) {
                if (value && value.length > 8) {
                    return `${value.substring(0, 4)} ${"*".repeat(value.length - 8).replace(/(.{4})/g, `$1 `)}${value.length % 4 ? " " : ""}${value.slice(-3)}`;
                }
                return value;
            }
        }
    }
</script>
<style scoped src="./style.css"></style>
<style scoped lang="less">
    .frm {
        height: 100%;
        overflow: auto;
        font-weight: 500;
    }

    .info-list {
        font-size: 14px;
        color: #808080;
        padding: 20px 0 20px 30px;
    }

    .info-list>div {
        padding: 10px 0;
    }
    .info-list>div>label:first-child {
        width: 72px;
        text-align: right;
        display: inline-block;
        vertical-align: top;
        margin-right: 20px;
        color: #4D4D4D;
        font-weight: bold;
    }

    .isview {
        margin-left: 10px;
        i {
            font-size:18px;
        }
    }
</style>